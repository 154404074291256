import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/SEO";
import Header from "../components/layout/Header.js";
import Title from "../components/layout/Title.js";
import SiteTitleQuery from "../components/SiteTitleQuery";
import ContactImage from "../images/inHouseImages/contactImage.png"

function Contact() {
  return (
    <SiteTitleQuery
      render={data => (
        <Layout title={data.site.siteMetadata.title}>
          <SEO title="Support" keywords={[`support`, `felanmälan`]} />
          <Header data={data} />
          <Title title="Kontakta oss" />

          <div className="bg-WHITE-400 font-sans leading-normal tracking-normal mb-12">
            <div className="container w-1/2 mx-auto flex flex-col md:flex-row items-center my-8 md:my-1">
              <div className="w-full h-auto lg:w-2/5 lg:py-6 text-center flex flex-row justify-center items-start">
                <img
                  src={ContactImage}
                  alt="helping"
                ></img>
              </div>
              <div className="flex flex-col w-full lg:w-1/2 justify-center items-start pt-12 pb-4 px-6">
                <h1 className="font-bold text-2xl my-4">
                  Hur kan vi hjälpa dig?
                </h1>
                <br></br>
                <h1 className="font-bold text-1xl my-4">
                  Växel:
                </h1>
                <p className="leading-tight mb-4">+46 (0)42-21 18 12</p>
                <p className="leading-tight mb-4">
                  Öppettider 08:00 - 16:00
                </p>
                <p className="leading-tight mb-4">
                  Lunchstängt 12:00 - 13:00
                </p>                                            
                <h1 className="font-bold text-1xl my-4">
                  Felanmälansväxel:
                </h1>
                <p className="leading-tight mb-4">+46 (0)10-188 20 00</p>
                <p className="leading-tight mb-4">
                  Öppettider 08:00 - 16:00
                </p>
                <br></br>
                <a className="leading-tight mb-4" href="mailto:info@psksyd.com">
                  info@psksyd.com
                </a>
              </div>
            </div>
          </div>
        </Layout>
      )}
    />
  );
}

export default Contact;
